define('frontend-app/provinces', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {

		AB: { tax: 0.05 },
		BC: { tax: 0.05 },
		SK: { tax: 0.05 },
		MB: { tax: 0.05 },
		ON: { tax: 0.13 },
		QC: { tax: 0.05 },
		NB: { tax: 0.15 },
		PE: { tax: 0.15 },
		NS: { tax: 0.15 },
		NL: { tax: 0.15 },
		YT: { tax: 0.05 },
		NU: { tax: 0.05 },
		NT: { tax: 0.05 }

	};
});