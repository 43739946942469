define('frontend-app/pods/contact/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		processing: false,
		success: false,
		error: false,
		message: "",

		contact: {
			subject: "",
			name: "",
			phone: "",
			email: "",
			message: ""
		},

		actions: {
			submitForm: function submitForm() {

				var self = this;

				if (this.get('processing')) return;

				this.set('processing', true);
				this.set('success', false);
				this.set('error', false);
				this.set('message', '');

				(0, _ajax.default)(_environment.default.base_url + '/api/contact', {

					credentials: 'include',
					method: 'post',
					body: JSON.stringify(this.get('contact'))

				}).then(function (response) {

					self.set('processing', false);
					self.set('message', response.success);
					self.set('success', true);
					self.set('contact', {
						company: null,
						name: null,
						tel: null,
						email: null,
						message: null
					});
				}).catch(function (err) {

					self.set('processing', false);

					err.json().then(function (data) {
						self.set('message', data.error);
						self.set('error', true);
						console.log(self.get('message'));
					});
				});
			}
		}

	});
});