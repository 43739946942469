define('frontend-app/pods/account/edit/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ajax, _environment, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

		account: Ember.inject.service(),

		model: function model() {

			return Ember.copy(this.get('account.data'));
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			controller.set('profile_success', false);
			controller.set('profile_error', false);
			controller.set('profile_message', '');

			controller.set('password_success', false);
			controller.set('password_error', false);
			controller.set('password_message', '');
		}
	});
});