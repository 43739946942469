define('frontend-app/pods/activate-account/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		intl: Ember.inject.service(),

		titleToken: Ember.computed(function () {
			return this.get('intl').t("accounts.activate.header");
		}),

		actions: {
			activateAccount: function activateAccount() {

				var self = this;
				self.controller.set('error', false);
				self.controller.set('success', false);

				(0, _ajax.default)(_environment.default.base_url + '/api/accounts/activate_account', {

					method: 'post',
					body: JSON.stringify({
						email: this.get('controller.email'),
						hash: this.get('controller.request'),
						pass1: this.get('controller.pass1'),
						pass2: this.get('controller.pass2')
					})

				}).then(function (data) {
					if (data.key == 'accounts.activate.activation-success') {
						self.controller.set('success', true);
						self.controller.set('message', self.get('intl').t(data.key, data.params || {}));
					} else {
						self.controller.set('error', true);
						self.controller.set('message', self.get('intl').t(data.key, data.params || {}));
					}
				}).catch(function (err) {

					err.json().then(function (data) {
						self.controller.set('error', true);
						self.controller.set('message', self.get('intl').t(data.key, data.params || {}));
					});
				});
			}
		}

	});
});