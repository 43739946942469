define('frontend-app/pods/account/transactions/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return (0, _ajax.default)(_environment.default.base_url + "/api/payments/list", {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-AppId': _environment.default.apiServer.headers['X-AppId']
        }
      });
    }
  });
});