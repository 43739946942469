define('frontend-app/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'frontend-app/config/environment', 'ember-fetch/ajax', 'frontend-app/provinces'], function (exports, _applicationRouteMixin, _environment, _ajax, _provinces) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

		intl: Ember.inject.service(),
		occupations: Ember.inject.service(),
		session: Ember.inject.service(),
		account: Ember.inject.service(),

		routeAfterAuthentication: "account",

		title: function title(tokens) {
			tokens = Ember.makeArray(tokens);
			tokens.unshift('CCAA');
			return tokens.reverse().join(' | ');
		},

		sessionAuthenticated: function sessionAuthenticated() {
			var attemptedTransition = this.get('session.attemptedTransition');
			var cookies = Ember.getOwner(this).lookup('service:cookies');
			var redirectTarget = cookies.read('ember_simple_auth-redirectTarget');
			console.log('sessionAuthenticated');
			if (attemptedTransition) {
				attemptedTransition.retry();
				this.set('session.attemptedTransition', null);
			} else if (redirectTarget) {
				this.transitionTo(redirectTarget);
				cookies.clear('ember_simple_auth-redirectTarget');
			} else {
				// unless specified, go to /account:
				console.log(this.get('controller.currentRouteName'));
				//if( ['news.view','members.view','procurements.view'].indexOf( this.get('controller.currentRouteName') ) == -1 )
				this.transitionTo(this.get('routeAfterAuthentication'));
			}
		},
		beforeModel: function beforeModel() {
			/* NOTE: if you lazily load translations, here is also where you would load them via `intl.addTranslations` */
			return this.get('intl').setLocale(['en-ca']);
		},
		model: function model() {
			return (0, _ajax.default)(_environment.default.base_url + '/api/data/occupations');
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			if (!this.get('session.isAuthenticated')) return;
			controller.set('signup.country', this.get('account.data.country'));
			controller.set('signup.state', this.get('account.data.state'));
		},


		actions: {
			dismissModal: function dismissModal() {
				this.set('controller.isShowingModal', false);
				this.set('controller.isPromptingAddAssessment', false);
			},
			toggleLanguage: function toggleLanguage() {
				if (this.get('intl.locale') == 'en-ca') {
					this.get('intl').setLocale('fr-ca');
				} else {
					this.get('intl').setLocale('en-ca');
				}
			},
			logout: function logout() {
				var self = this;
				self.get('controller.session').invalidate();
			},
			loading: function loading(transition, originRoute) {
				var controller = this.controllerFor('application');
				controller.set('loading', true);
				transition.promise.finally(function () {
					controller.set('loading', false);
				});
				return true;
			},
			willTransition: function willTransition() {
				this.set('controller.navigation_visible', false);
			},
			prompt_add_assessment: function prompt_add_assessment(code) {
				this.set('controller.signup.self_assessments', [code]);
				this.set('controller.selectedOccupation', code);
				this.set('controller.isShowingModal', true);
				this.set('controller.isPromptingAddAssessment', true);
			}
		}
	});
});