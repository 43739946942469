define('frontend-app/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'npm:pouchdb', 'npm:pouchdb-authentication', 'frontend-app/config/environment'], function (exports, _base, _npmPouchdb, _npmPouchdbAuthentication, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    fastboot: Ember.inject.service(),
    // session: service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        // return fetch( config.base_url + '/api/db/_session', {credentials: 'include',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'X-AppId': config.apiServer.headers['X-AppId']}})
        return fetch(_environment.default.base_url + '/api/auth', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-AppId': _environment.default.apiServer.headers['X-AppId'] } }).then(function (response) {
          return response.json();
        }).then(function (response) {
          if ('userCtx' in response && response.userCtx.name === null) {
            reject();
          } else if ('userCtx' in response && response.userCtx.roles.indexOf("ccaa-active") == -1) {
            reject({ reason: "You haven't been given permission to access this site." });
          } else if ('errors' in response) {
            reject({ reason: response.errors[0].detail });
          }

          Ember.run(function () {
            fetch(_environment.default.base_url + '/api/account', {
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                'X-AppId': _environment.default.apiServer.headers['X-AppId']
              } }).then(function (response) {
              return response.json();
            }).then(function (userdoc) {
              resolve({
                data: userdoc.data,
                roles: userdoc.roles,
                user: userdoc.user
              });
            });
          });
        });
      });
    },

    authenticate: function authenticate(options) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return fetch(_environment.default.base_url + '/api/auth', {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AppId': _environment.default.apiServer.headers['X-AppId']
          },
          body: JSON.stringify({
            user: options.user,
            pass: options.pass
          })
        }).then(function (response) {
          return response.json();
        })

        // PouchDB.plugin(PouchDBAuth);
        // var db = new PouchDB( config.base_url + '/api/db/test', {skipSetup: true});
        // return db.login(options.user, options.pass)
        // .then((response) => {
        //   if(response.name === null){
        //     // _admin role records don't show the name in the first _session call
        //     // https://issues.apache.org/jira/browse/COUCHDB-1356
        //     return db.getSession().then((subsequent) => {
        //       if("name" in subsequent.userCtx){
        //         return db.getUser(subsequent.userCtx.name);
        //       }
        //       else{
        //         reject();
        //       }
        //     })
        //   }
        //
        //   // normal user logged in
        //   return db.getUser(response.name);
        // }, function(xhr) {reject(xhr);})
        .then(function (response) {
          if ('errors' in response) {
            reject({ reason: response.errors[0].detail });
          }

          if (response.roles.indexOf("ccaa-active") == -1) reject({ reason: "You haven't been given permission to access this site." });

          Ember.run(function () {
            fetch(_environment.default.base_url + '/api/account', {
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                'X-AppId': _environment.default.apiServer.headers['X-AppId']
              }
            }).then(function (response) {
              return response.json();
            }).then(function (userdoc) {
              resolve({
                data: userdoc.data,
                roles: userdoc.roles,
                user: userdoc.user
              });
            });
          });
        });
      });
    },

    invalidate: function invalidate() {
      return fetch(_environment.default.base_url + '/api/auth', {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-AppId': _environment.default.apiServer.headers['X-AppId']
        } });
    }

  });
});