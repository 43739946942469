define('frontend-app/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    // Add your transitions here, like:
    //   this.transition(
    //     this.fromRoute('people.index'),
    //     this.toRoute('people.detail'),
    //     this.use('toLeft'),
    //     this.reverse('toRight')
    //   );

    this.transition(
    // this works
    this.use('explode', {
      pick: '.ember-modal-dialog',
      use: ['to-down', { duration: 250, easing: 'easeOutQuart' }]
    }, {
      pick: '.ember-modal-overlay',
      use: ['fade', { duration: 250, easing: 'easeOutQuart' }]
    }));
  };
});