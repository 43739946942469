define('frontend-app/pods/questions/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		titleToken: 'Frequently Asked Questions',
		model: function model() {

			return (0, _ajax.default)(_environment.default.base_url + '/api/data/faqs').then(function (data) {
				return Ember.A(data);
			});
		},


		init: function init() {
			this.setHeadTags();
		},

		setHeadTags: function setHeadTags() {
			var headTags = [{
				type: 'meta',
				attrs: {
					property: 'og:title',
					content: "Frequently Asked Questions CCAA"
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:description',
					content: "Find employment that puts your skills to use."
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:type',
					content: 'article'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:image',
					content: _environment.default.base_url + '/assets/CCAA-logo-og.png'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:url',
					content: _environment.default.base_url + '/questions'
				}
			}];

			this.set('headTags', headTags);
		}
	});
});