define('frontend-app/pods/components/questions/question-likert-scale/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['question', "question-likert-scale"],

		widthOfPills: null,
		itemArray: [],

		didInsertElement: function didInsertElement() {

			var itemArray = [];
			var count = 0;
			var lv = this.get('question.data.low_value');
			var hv = this.get('question.data.high_value');
			var i = this.get('question.data.interval');
			for (var x = lv; x <= hv; x += i) {
				count++;
				itemArray.push(x);
			}
			this.set('widthOfPills', Math.round(100 / count));
			this.set('itemArray', itemArray);

			if (this.get('response') !== null) return;
			this.set('response', {
				value: null,
				has_annotation: false,
				annotation: null
			});
		},


		actions: {
			setResponse: function setResponse(value) {
				this.set('response.value', value);
				this.sendAction('change', this.get('section_index'), this.get('slide_index'), this.get('question_index'), this.get('response'));
			}
		}

	});
});