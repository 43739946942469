define('frontend-app/components/memory-scroll', ['exports', 'memory-scroll/components/memory-scroll'], function (exports, _memoryScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _memoryScroll.default;
    }
  });
});