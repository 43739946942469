define('frontend-app/pods/components/questions/question-multiple-choice/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['question', "question-multiple-select"],

		didInsertElement: function didInsertElement() {
			if (this.get('response') !== null) return;
			this.set('response', {
				value: Ember.A(),
				has_annotation: false,
				annotation: null
			});
		},


		actions: {
			toggleAnswer: function toggleAnswer(index) {
				var current = Ember.copy(this.get('response.value'));
				console.log(JSON.stringify(current), index, current.indexOf(index));
				if (current.indexOf(index) == -1) {
					current.push(index);
					this.set('response.value', current);
				} else {
					current = Ember.A(current).without(index);
					this.set('response.value', current);
				}
				this.send('update');
			},
			selectAnswer: function selectAnswer(index) {
				this.set('response.value', index);
				this.send('update');
			},
			update: function update() {
				this.sendAction('change', this.get('section_index'), this.get('slide_index'), this.get('question_index'), this.get('response'));
			}
		}
	});
});