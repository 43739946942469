define('frontend-app/pods/sign-up/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _environment, _ajax, _unauthenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
		titleToken: 'Sign Up',
		model: function model() {
			return (0, _ajax.default)(_environment.default.base_url + '/api/data/occupations');
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			controller.set('showCheckout', false);
		}
	});
});