define('frontend-app/pods/assessments/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		titleToken: 'Assessment'

	});
});