define('frontend-app/pods/sign-in/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		session: Ember.inject.service('session'),

		actions: {
			authenticate: function authenticate() {

				var self = this;

				var data = this.getProperties('user', 'pass');
				this.get('session').authenticate('authenticator:custom', data).catch(function (reason) {

					self.set('errorMessage', reason);
				});
			}
		}

	});
});