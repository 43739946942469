define('frontend-app/helpers/format-currency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCurrency = formatCurrency;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function formatCurrency(_ref) {
    var _ref2 = _toArray(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    var dollars = Math.floor(value / 100);
    var cents = Math.floor(value % 100);
    var sign = '$';

    if (cents.toString().length === 1) {
      cents = '0' + cents;
    }
    return '' + sign + dollars + '.' + cents;
  }

  exports.default = Ember.Helper.helper(formatCurrency);
});