define('frontend-app/pods/account/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ajax, _environment, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

		titleToken: 'Account'
	});
});