define('frontend-app/pods/components/questions/question-annotation/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['question', 'question-annotation'],

		actions: {
			toggleAnnotation: function toggleAnnotation() {
				if (this.get('response.has_annotation')) {
					this.set('response.annotation', null);
				}
				this.toggleProperty('response.has_annotation');
			}
		}

	});
});