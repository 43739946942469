define('frontend-app/pods/sign-up/controller', ['exports', 'npm:is-valid-email', 'frontend-app/config/environment', 'ember-fetch/ajax', 'frontend-app/provinces'], function (exports, _npmIsValidEmail, _environment, _ajax, _provinces) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		intl: Ember.inject.service(),

		isError: false,
		errorMessage: '',
		isSuccess: false,
		successMessage: '',

		showCheckout: false,

		checkoutDescription: "Accelerated Certification Access Fee",

		is_processing_payment: false,

		provinces: _provinces.default,
		provinceList: Object.keys(_provinces.default).sort(),

		signup: {
			self_assessments: [],
			name: "",
			surname: "",
			email: "",
			phone: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			code: "",
			country: "Canada",
			credentials: "",
			history: ""
		},

		occupationLookup: Ember.computed('model', function () {
			return this.get('model').reduce(function (prev, current, list) {
				prev[current.key] = parseFloat(current.value.price_self_assessment * 100) || 0;
				return prev;
			}, {});
		}),

		subtotal: Ember.computed('signup.self_assessments.[]', function () {
			var lookup = this.get('occupationLookup');
			return this.get('signup.self_assessments').map(function (item) {
				console.log(item, lookup[item]);
				return lookup[item];
			}).reduce(function (prev, current, list) {
				return parseFloat(prev) + parseFloat(current);
			}, 0);
		}),

		tax: Ember.computed('subtotal', 'signup.country', 'signup.state', function () {

			if (this.get('signup.country') !== 'Canada') return 0;
			if (!this.get('signup.state')) return 0;
			if (!('tax' in this.get('provinces')[this.get('signup.state')])) return 0;

			// return tax as an integer representing the number of cents of tax
			return Math.round(this.get('subtotal') * this.get('provinces')[this.get('signup.state')].tax);
		}),

		checkoutTotal: Ember.computed('subtotal', 'tax', function () {

			return this.get('subtotal') + this.get('tax');
		}),

		validate: function validate() {
			var self = this;
			this.set('isError', false);
			var submission = this.get('signup');

			var not_empty = ['name', 'surname', 'phone', 'address1', 'city', 'state', 'code', 'country'];

			not_empty = not_empty.filter(function (key) {
				if (self.get('signup.' + key) == "") return true;
				return false;
			}).map(function (key) {
				return self.get('intl').t('sign-up.assessment.form.' + key);
			});
			if (not_empty.length > 0) {
				this.set('errorMessage', this.get('intl').t('sign-up.assessment.form.require-fields', { which: not_empty.join(', ') }));
				this.set('isError', true);
				return false;
			}
			if (submission.self_assessments.length == 0) {
				this.set('errorMessage', this.get('intl').t('accounts.form.error_missing_assessment'));
				this.set('isError', true);
				return false;
			}
			if (!submission.email) {
				this.set('errorMessage', this.get('intl').t('accounts.form.error_email_required'));
				this.set('isError', true);
				return false;
			}
			if (!(0, _npmIsValidEmail.default)(submission.email)) {
				this.set('errorMessage', this.get('intl').t('global.alerts.invalid_email'));
				this.set('isError', true);
				return false;
			}
			return true;
		},

		actions: {
			setCountry: function setCountry(which) {
				this.set('signup.country', which);
				this.set('signup.state', '');
			},
			setState: function setState(value, event) {
				this.set('signup.state', value);
			},
			closeCheckout: function closeCheckout() {
				this.set('showCheckout', false);
			},
			processStripeToken: function processStripeToken(token, args) {

				// at this point we have validated input locally, and have a valid Stripe token to process
				// we will process the payment by making a request to /api/accounts/request-account
				// it will validate the request on the server side as it did on the client side
				// if input is valid, it will check to see whether a payment is involved
				// if a payment is involved, it will process the Stripe token first,
				//   if it goes through, will proceed with the account creation
				//   if it fails, it will notify the user so they can check out again

				var self = this;

				this.set('is_processing_payment', true);

				(0, _ajax.default)(_environment.default.base_url + '/api/accounts/request-account', {
					credentials: 'include',
					method: 'post',
					body: JSON.stringify({
						token: token,
						subtotal: self.get('subtotal'),
						tax: self.get('tax'),
						total: self.get('checkoutTotal'),
						details: self.get('signup')
					})

				}).then(function (response) {

					console.log(response);
					// reload the model so the invoice status changes
					/*
     fetch( config.base_url + '/api/invoices/' + invoice_id , { credentials: 'include' }).then( function(updatedInvoice){
     	self.set('model', updatedInvoice);
     })
     */

					self.set('is_processing_payment', false);
					self.set('showCheckout', false);
					self.set('isError', false);
					self.set('isSuccess', true);
					self.set('successMessage', self.get('intl').t(response.key, response.params || {}));
					self.set('signup', {
						self_assessments: [],
						name: "",
						surname: "",
						email: "",
						phone: "",
						address1: "",
						address2: "",
						city: "",
						state: "",
						code: "",
						country: "Canada",
						credentials: "",
						history: ""
					});
				}).catch(function (err) {

					console.log(err);
					self.set('is_processing_payment', false);
					self.set('showCheckout', false);
					self.set('isError', true);
					err.json().then(function (response) {
						self.set('errorMessage', self.get('intl').t(response.key, response.params || {}));
					});
				});
			},
			submitForm: function submitForm() {

				var self = this;

				if (!Ember.run.bind(this, this.get('validate'))()) return false;

				if (this.get('checkoutTotal') > 0) {
					this.set('showCheckout', true);
					return;
				}

				console.log(self.get('subtotal'), self.get('tax'), self.get('checkoutTotal'));

				(0, _ajax.default)(_environment.default.base_url + '/api/accounts/request-account', {
					method: 'POST',
					body: JSON.stringify({
						token: false,
						subtotal: self.get('subtotal'),
						tax: self.get('tax'),
						total: self.get('checkoutTotal'),
						details: this.get('signup')
					}),
					credentials: 'include'

				}).then(function (response) {

					self.set('isSuccess', true);
					self.set('successMessage', self.get('intl').t(response.key, response.params || {}));
					self.set('signup', {
						self_assessments: [],
						name: "",
						surname: "",
						email: "",
						phone: "",
						address1: "",
						address2: "",
						city: "",
						state: "",
						code: "",
						country: "Canada",
						credentials: "",
						history: ""
					});
				}).catch(function (err) {

					err.json().then(function (response) {
						self.set('isError', true);
						self.set('errorMessage', self.get('intl').t(response.key, response.params || {}));
					});
				});
			}
		}

	});
});