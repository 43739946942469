define('frontend-app/pods/components/questions/question-binary/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['question', "question-binary"],

		didInsertElement: function didInsertElement() {
			if (this.get('response') !== null) return;
			this.set('response', {
				value: null,
				has_annotation: false,
				annotation: null
			});
		},


		actions: {
			selectAnswer: function selectAnswer(index) {
				this.set('response.value', index);
				this.send('update');
			},
			update: function update() {
				this.sendAction('change', this.get('section_index'), this.get('slide_index'), this.get('question_index'), this.get('response'));
			}
		}

	});
});