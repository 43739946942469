define('frontend-app/pods/account/index/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		fastboot: Ember.inject.service(),
		account: Ember.inject.service(),
		occupations: Ember.inject.service(),

		activity_icons: {
			'access_granted': 'unlock',
			'access_revoked': 'lock',
			'assessment_submitted': 'flag-checkered',
			'account_activated': 'key',
			'account_created': 'user-plus',
			'account_requested': 'id-card-o',
			'assessment_reset': 'refresh'
		},

		count_incomplete: Ember.computed('in_progress', 'not_started', function () {
			return this.get('in_progress.length') + this.get('not_started.length');
		}),

		not_started: Ember.computed('model.existing_assessments', 'account.data.self_assessments', function () {
			if (this.get('fastboot.isFastBoot')) return [];
			var existing = this.get('model.user_assessments').map(function (a) {
				return a.value.code;
			});
			var all = this.get('account.data.self_assessments');

			return all.filter(function (code) {
				if (existing.indexOf(code) !== -1) return false;return true;
			});
		}),

		in_progress: Ember.computed('model.user_assessments', 'account.data.self_assessments', function () {
			var self = this;
			if (this.get('fastboot.isFastBoot')) return [];

			return this.get('model.user_assessments').filter(function (a) {
				return a.value.status == 'draft';
			}).filter(function (a) {
				return self.get('account.data.self_assessments').includes(a.value.code);
			});
		}),

		in_review: Ember.computed('model.user_assessments', 'account.data.self_assessments', function () {
			if (this.get('fastboot.isFastBoot')) return [];
			var filtered = this.get('model.user_assessments').filter(function (a) {
				if (a.value.status == 'review') return true;
			});
			return filtered;
		}),

		completed: Ember.computed('model.user_assessments', 'account.data.self_assessments', function () {
			if (this.get('fastboot.isFastBoot')) return [];
			var filtered = this.get('model.user_assessments').filter(function (a) {
				if (a.value.status == 'archive') return true;
			});
			return filtered;
		})

	});
});