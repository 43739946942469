define('frontend-app/pods/activate-account/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		queryParams: ['request', 'email']

	});
});