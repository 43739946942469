define('frontend-app/services/account', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		session: Ember.inject.service(),

		is_admin: Ember.computed('session.data.authenticated', function () {
			if (!this.get('session.isAuthenticated')) return false;
			return (this.get('session.data.authenticated.roles') || []).indexOf('ccaa-admin') !== -1;
		}),

		data: Ember.computed('session.data.authenticated', function () {
			var data = this.get('session.data.authenticated.data');
			if (!Ember.isEmpty(data)) {
				return data;
			}
		})
	});
});