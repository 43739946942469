define('frontend-app/pods/questions/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		activeCategory: 'certification',

		questionList: Ember.computed('model', 'activeCategory', function () {
			var self = this;
			return this.get('model').filter(function (item) {
				return item.value.category == self.get('activeCategory');
			});
		}),

		actions: {
			setCategory: function setCategory(which) {
				this.set('activeCategory', which);
			}
		}

	});
});